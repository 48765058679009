import Footer from '../components/common/Footer';
import Button from '../components/common/Button';
import { useNavigate } from 'react-router-dom';
import Header from '../components/common/Header';
import logo from '../assets/logo.png'
import { useTranslation } from 'react-i18next';

export default function PaymentHomeScreen() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="flex flex-col w-full min-h-screen">
            <div className="flex flex-row p-5 flex-wrap justify-between bg-[#F5F5F5] min-h-[55px]">
                <img src={logo} alt="Logo" className="w-[140px] h-[22.33px]" />
            </div>
            <div className="px-6 flex flex-col justify-center gap-6 flex-grow">
                <div className="flex flex-col gap-[8px] text-center">
                    <h6 className="text-[18px] font-bold leading-[27px]">
                        {t('welcome')}
                    </h6>
                    <p className="text-[14px] font-normal leading-[21px]">
                        {t('paymentPrompt')}
                    </p>
                </div>

                <div className="text-center flex flex-col gap-[4px]">
                    <p className="text-sm">{t('paymentAmount')}</p>
                    <p className="text-5xl font-bold text-[#03A9F4]">$10.00</p>
                    <p className="text-lg font-bold">{t('paymentSummary')}</p>
                </div>

                <Button
                    text={t('payButtonText')}
                    onClick={() => navigate('/payment-summary')}
                />
            </div>
            <div className='p-5 '>
                <Footer />
            </div>


        </div>
    );
}
