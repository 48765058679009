// src/components/Footer.tsx
import React from 'react';

const Footer: React.FC = () => {
    return (
        <div className='flex flex-row flex-wrap gap-[5px] justify-between font-medium text-[#00000061] text-[13px] leading-[22px]'>
            <div>
                <a href='./privacy-policy'>
                    Privacy Policy
                </a>
            </div>
            <div>
                © LynkPay
            </div>
            <div>
                <a href='./terms-and-service'>
                    Terms of Service
                </a>
            </div>
        </div>
    );
}

export default Footer;
