import React, { useState } from "react";

import failed from "../assets/failed.png";
import Footer from "../components/common/Footer";
import DropDown from "../components/common/DropDown";
import { SelectChangeEvent, TextField } from "@mui/material";
import CustomTextField from "../components/common/CustomTextField";
import Button from "../components/common/Button";
import { bottomSheet } from "react-simple-bottom-sheet";
import PaymentFailedCancelled from "./PaymentFailedCancelled";
import PaymentFailedTimeExp from "./PaymentFailedTimeExp";

const PaymentCancellationReason: React.FC = () => {
  const [reason, setReason] = useState("");
  const [textFieldValue, setTextFieldValue] = useState("");

  const handleSelectValueChange = (event: SelectChangeEvent) => {
    setReason(event.target.value as string);
  };

  const handleTextFieldValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextFieldValue(event.target.value);
  };

  const openPaymentAcquirer = () =>
    bottomSheet.create({
      content: <PaymentFailedTimeExp />,
    });

  const openCancelConfirmation = () =>
    bottomSheet.create({
      content: <PaymentFailedCancelled />,
    });

  const options = [
    { value: "Delayed", label: "Delayed" },
    { value: "Insufficient Fund", label: "Insufficient Fund" },
  ];

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col justify-center gap-3 px-6 pt-6">
        <div className="flex flex-col justify-center items-center gap-y-[12px]">
          <img
            src={failed}
            alt="failed-img"
            className="w-auto h-auto object-contain"
          />
          <p className="leading-7 font-bold text-[24px] text-center text-[#000000]">
            Are you sure want to cancel?
          </p>
        </div>
        <div className="flex flex-col gap-2 w-full">
          <div className="flex flex-col items-start justify-center gap-1">
            <div className="font-normal text-sm text-[#00000099] px-2">
              Reason for cancellation
            </div>
            <DropDown
              options={options}
              value={reason}
              onChange={handleSelectValueChange}
            />
          </div>
          <div className="flex flex-col items-start justify-center gap-1">
            <p className="font-normal text-sm text-[#00000099] px-2">
              Remarks (optional)
            </p>
            <CustomTextField value={textFieldValue} onChange={handleTextFieldValueChange} sx={{ width: '100%' }} />
          </div>
          <div className="flex flex-col items-start justify-center gap-[16px] py-[20px]">
            <Button
              text='Cancel Payment'
              color={"#D32F2F"}
              onClick={openCancelConfirmation}
            />
            <Button text="Back to payment" color="none" textColor="#000000DE" onClick={openPaymentAcquirer} />
          </div>
        </div>
      </div>
      <div className='px-6 pb-6'>
        <Footer />
      </div>
    </div>
  );
};

export default PaymentCancellationReason;
