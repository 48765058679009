import React from 'react';
import success from '../assets/success.png'
import Footer from '../components/common/Footer';


interface PaymentDetail {
  label: string;
  value: string;
}

const paymentDetails: PaymentDetail[] = [
  { label: 'Transaction ID', value: 'LP12345678923456' },
  { label: 'Name', value: 'Lynkpay' },
  { label: 'Payment Reference', value: '345678923456' },
  { label: 'Payment Type', value: 'Card' },
];


const handleShare = async () => {
  if (navigator.share) {
    try {
      await navigator.share({
        title: 'Invoice Details',
        text: 'Here are your payment details for the transaction.',
        url: '/path/to/your-invoice.pdf',
      });
      console.log('Share successful');
    } catch (error) {
      console.log('Error sharing:', error);
    }
  } else {
    alert('Share not supported on this device.');
  }
};

const handleDownloadInvoice = () => {
  const link = document.createElement('a');
  link.href = '/path-to-sample-invoice.pdf';
  link.download = 'invoice.pdf';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const Paid: React.FC = () => {
  return (
    <div className='flex flex-col'>
      <div className='flex flex-col justify-center items-center gap-3 px-6 pt-6'>
        <div>
          <img src={success} alt='success-img' className='w-auto  h-auto object-contain' />
        </div>
        <div className='flex flex-col items-center gap-1 pb-5'>
          <div className='text-[14px] font-normal leading-[21px]'>You Paid</div>
          <div className='text-[24px] font-bold leading-[28px]'>Paid $10.00</div>
          <div className='text-[14px] font-normal leading-[21px]'>Today, 10.30 AM</div>
        </div>
      </div>

      <div className='flex flex-col gap-[16px] bg-[#F5F5F5]  p-6'>
        <div className='flex flex-col'>
          {paymentDetails.map((detail, index) => (
            <div className='payment-details-container-list' key={index}>
              <div className='font-normal text-[14px] leading-[21px]'>{detail.label}</div>
              <div className='font-normal text-[14px] leading-[21px]'>{detail.value}</div>
            </div>
          ))}
        </div>
        <div className='flex flex-row justify-around'>
          <div className='flex flex-row px-2 py-[6px]' onClick={handleShare}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75718 11.7022C7.19894 12.5613 6.23113 13.13 5.13024 13.13C3.40247 13.13 2 11.7274 2 9.99973C2 8.27207 3.40258 6.86949 5.13024 6.86949C6.23108 6.86949 7.1989 7.43817 7.75718 8.29729L13.9491 5.20177C13.8691 4.96091 13.8256 4.70265 13.8256 4.43483C13.8256 3.09051 14.916 2 16.2605 2C17.6049 2 18.6953 3.0904 18.6953 4.43483C18.6953 5.77927 17.6049 6.86967 16.2605 6.86967C15.4327 6.86967 14.7005 6.45576 14.2605 5.82361L8.06859 8.92002C8.19293 9.25653 8.26076 9.61999 8.26076 10C8.26076 10.38 8.19293 10.7435 8.06859 11.08L14.2605 14.1764C14.7005 13.5442 15.4327 13.1303 16.2605 13.1303C17.6048 13.1303 18.6953 14.2207 18.6953 15.5652C18.6953 16.9096 17.6049 18 16.2605 18C14.916 18 13.8256 16.9096 13.8256 15.5652C13.8256 15.2974 13.8691 15.0391 13.9491 14.7982L7.75718 11.7022ZM7.30851 8.91096C6.90851 8.11273 6.08245 7.5649 5.13029 7.5649C3.78597 7.5649 2.69546 8.6553 2.69546 9.99973C2.69546 11.3442 3.78586 12.4346 5.13029 12.4346C6.08243 12.4346 6.90851 11.8868 7.30851 11.0885C7.47285 10.7615 7.56501 10.3911 7.56501 9.99984C7.56501 9.60855 7.47284 9.23876 7.30851 8.91096ZM14.7049 5.21196C14.9901 5.78238 15.5806 6.17368 16.2606 6.17368C17.2205 6.17368 17.9996 5.39456 17.9996 4.43459C17.9996 3.47461 17.2205 2.6955 16.2606 2.6955C15.3006 2.6955 14.5215 3.47461 14.5215 4.43459C14.5215 4.71458 14.5876 4.97892 14.7049 5.21196ZM14.7049 14.7875C14.5876 15.0214 14.5215 15.2857 14.5215 15.5648C14.5215 16.5248 15.3006 17.3039 16.2606 17.3039C17.2205 17.3039 17.9996 16.5248 17.9996 15.5648C17.9996 14.6049 17.2205 13.8257 16.2606 13.8257C15.5806 13.8257 14.9902 14.217 14.7049 14.7875Z" fill="black" />
            </svg>
            <button className='ml-2 font-medium text-[14px] leading-[21px]'>
              Share Invoice
            </button>
          </div>
          <div className='flex flex-row px-2 py-[6px] cursor-pointer' onClick={handleDownloadInvoice}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.3129 2.00021C10.1571 2.00021 10.0081 2.06209 9.89813 2.17208C9.78813 2.28208 9.72626 2.43103 9.72626 2.58687V13.1165L5.80546 9.71454C5.68744 9.61256 5.53391 9.561 5.37809 9.57131C5.22226 9.58277 5.07791 9.65496 4.97592 9.77297C4.7628 10.0182 4.78916 10.3883 5.03435 10.6002L9.89818 14.8179V14.819L9.90162 14.8213L9.9486 14.8614H9.94745C9.98182 14.8866 10.0185 14.9084 10.0574 14.9256C10.0701 14.9336 10.0827 14.9405 10.0964 14.9485C10.3084 15.0436 10.5581 15.0035 10.7312 14.8465L15.2938 10.5843V10.5854C15.5309 10.3643 15.5447 9.99307 15.3247 9.75589C15.2181 9.64246 15.0703 9.57485 14.9157 9.57027H14.9145C14.7587 9.56454 14.6086 9.62183 14.4952 9.72839L10.9008 13.0854V2.58664C10.9008 2.26237 10.6373 2 10.3142 2L10.3129 2.00021ZM1.5878 12.3937C1.43197 12.3925 1.28186 12.4544 1.17187 12.5655C1.06187 12.6755 1 12.8245 1 12.9803V15.4415C1 16.8473 2.1538 18.0011 3.55968 18H17.0662C18.4721 18 19.6259 16.8473 19.6259 15.4415V12.9803C19.6259 12.8245 19.564 12.6755 19.454 12.5655C19.344 12.4556 19.1951 12.3937 19.0392 12.3937C18.8834 12.3925 18.7344 12.4544 18.6244 12.5655C18.5133 12.6755 18.4514 12.8245 18.4526 12.9803V15.4415C18.4526 16.2137 17.8384 16.8279 17.0662 16.8279H3.55966C2.78741 16.8279 2.1744 16.2149 2.1744 15.4415V12.9803C2.1744 12.8245 2.11253 12.6755 2.00253 12.5655C1.89254 12.4556 1.74363 12.3937 1.5878 12.3937Z" fill="black" />
            </svg>

            <button className='ml-2 font-medium text-[14px] leading-[21px]'>
              Download
            </button>
          </div>
        </div>
        <div className='bg-[#F5F5F5]'>
          <Footer />
        </div>


      </div>

    </div>
  );
};

export default Paid;
