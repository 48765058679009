import React, { useState, useEffect } from 'react';
import { bottomSheet } from "react-simple-bottom-sheet";
import Paid from './Paid';
import PaymentFailedCancelled from './PaymentFailedCancelled';
import PaymentFailedCVV from './PaymentFailedCVV';
import Button from '../components/common/Button';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import PaymentFailedTimeExp from './PaymentFailedTimeExp';
import { useTranslation } from 'react-i18next';

const PaymentSummary: React.FC = () => {

  const { t } = useTranslation();
  const [isBookingDetailsOpen, setIsBookingDetailsOpen] = useState(true);

  const openBottomSheet = (content: React.ReactNode) =>
    bottomSheet.create({ content });

  const toggleBookingDetails = () => {
    setIsBookingDetailsOpen((prev) => !prev);
  };

  const paymentDetails = [
    { label: 'Name', value: 'Lynkpay' },
    { label: 'Payment Reference', value: '12345' },
  ];

  const fareBreakup = [
    { label: 'Trip Fare', value: '$7.00' },
    { label: 'Service Charge', value: '$2.00' },
    { label: 'Tax', value: '$1.00' },
    { label: 'Total', value: '$10.00', isBold: true },
  ];

  const bookingDetails = [
    { label: 'Booking ID', value: '987654' },
    { label: 'Booking Date', value: 'Oct 22, 2024' },
    { label: 'Service Type', value: 'Taxi Booking' },
    { label: 'Pickup', value: 'Main St, Downtown' },
    { label: 'Drop', value: 'Elm St, Uptown' },
    { label: 'Distance Covered', value: '10 miles' },
  ];

  const [timeLeft, setTimeLeft] = useState(300);
  const [expired, setExpired] = useState(false);

  // Countdown logic
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prev => {
        if (prev <= 1) {
          clearInterval(timer);
          setExpired(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (expired) {
      openBottomSheet(<PaymentFailedTimeExp />);
    }
  }, [expired]);

  const formatTimeLeft = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };


  return (
    <div className="flex flex-col w-full">

      <Header timeLeft={formatTimeLeft()} />

      <div className="flex flex-col pt-6 px-6 bg-[white] w-full gap-[20px] screen-size mb-[62px]">

        <div className="text-center flex flex-col gap-[4px]">
          <p className="text-sm ">You are paying</p>
          <p className="text-5xl font-bold text-[#03A9F4]">$10.00</p>
          <p className="text-lg font-bold">Payment Summary</p>
        </div>

        {/* Payment Info */}
        <SummarySection title="Payment Details" details={paymentDetails} />

        {/* Fare Breakup */}
        <SummarySection title="Fare Breakup" details={fareBreakup} />

        {/* Booking Details */}
        <div className="border border-gray-200  rounded-md">
          <button
            onClick={toggleBookingDetails}
            className="flex justify-between items-center w-full text-sm font-bold px-3 pt-4"
          >
            Booking Details
            <span
              className={`transition-transform duration-300 ${isBookingDetailsOpen ? 'rotate-180' : ''}`}
            >
              {isBookingDetailsOpen ? (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.29289 15.707C4.90237 15.3165 4.90237 14.6834 5.29289 14.2928L11.2929 8.29284C11.6834 7.90234 12.3166 7.90234 12.7071 8.29284L18.7071 14.2928C19.0976 14.6834 19.0976 15.3165 18.7071 15.707C18.3166 16.0976 17.6834 16.0976 17.2929 15.707L12 10.4141L6.70711 15.707C6.31658 16.0976 5.68342 16.0976 5.29289 15.707Z"
                    fill="black"
                    fillOpacity="0.87"
                  />
                </svg>
              ) : (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.29289 15.707C4.90237 15.3165 4.90237 14.6834 5.29289 14.2928L11.2929 8.29284C11.6834 7.90234 12.3166 7.90234 12.7071 8.29284L18.7071 14.2928C19.0976 14.6834 19.0976 15.3165 18.7071 15.707C18.3166 16.0976 17.6834 16.0976 17.2929 15.707L12 10.4141L6.70711 15.707C6.31658 16.0976 5.68342 16.0976 5.29289 15.707Z"
                    fill="black"
                    fillOpacity="0.87"
                  />
                </svg>
              )}
            </span>

          </button>
          {isBookingDetailsOpen && <SummarySection details={bookingDetails} noBorder />}
        </div>

        {/* Action Buttons */}
        <div className='flex flex-col justify-center text-center gap-2'>
          <button onClick={() => openBottomSheet(<PaymentFailedCancelled />)}>Cancel Payment</button>
          <button onClick={() => openBottomSheet(<PaymentFailedCVV />)}>Temp PaymentFailedCVV</button>
        
        </div>
      </div>
      <Footer />

      <div className="bg-white w-full flex flex-col fixed bottom-0 shadow-[0_-4px_8px_rgba(0,0,0,0.1)]">
        <div className="px-[24px] py-[20px]">
          <div
            onClick={() => openBottomSheet(<Paid />)}
            className="flex w-full flex-col px-[22px] py-[8px] rounded text-center cursor-pointer bg-[#2196F3]"
          >
            <span className="text-[15px] font-medium leading-[26px] text-white">Pay Now</span>
          </div>
        </div>
      </div>


    </div>
  );
};

interface SummarySectionProps {
  title?: string;
  details: Array<{ label: string; value: string; isBold?: boolean }>;
  noBorder?: boolean;
}

const SummarySection: React.FC<SummarySectionProps> = ({ title, details, noBorder }) => (
  <div className={` flex flex-col  gap-[8px] p-4 rounded-md ${noBorder ? '' : 'border border-gray-200'}`}>
    {title && <p className="text-[14px] font-bold leading-[21px]">{title}</p>}
    {details.map((item, index) => (
      <div key={index} className="flex flex-row justify-between ">
        <div className="text-[14px] font-normal leading-[21px]">{item.label}</div>
        <div className={`text-[14px] font-normal leading-[21px] ${item.isBold ? 'font-bold' : ''}`}>{item.value}</div>
      </div>
    ))}
  </div>
);

export default PaymentSummary;
