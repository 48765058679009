import logo from '../assets/logo.png';

export default function PrivacyPolicy() {
  return (
    <div className="flex flex-col w-full min-h-screen">
      <div className="flex flex-row p-5 flex-wrap justify-between bg-[#F5F5F5] min-h-[55px]">
        <img src={logo} alt="Logo" className="w-[140px] h-[22.33px]" />
      </div>
      PrivacyPolicy Page
    </div>
  )
}
