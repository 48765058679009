import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

// Define the props for the Layout component
interface LayoutProps {
    children?: ReactNode; // Optional children prop
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <div className='flex w-[100vw] overflow-hidden'>
            {/* Children can be rendered here if needed in the future */}
            {children}
            <Outlet />

        </div>
    );
}

export default Layout;
