import React from 'react';

interface ButtonProps {
  text: string;
  onClick?: () => void;
  color?: string; 
  textColor?: string;
}

const Button: React.FC<ButtonProps> = ({ text, onClick, color = '#2196F3', textColor = '#FFFFFF'}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      onClick={handleClick} // Attach the click handler
      className="flex w-full flex-col px-[22px] py-[8px] rounded text-center cursor-pointer"
      style={{ backgroundColor: color }} 
    >
      <span className={'text-[15px] font-medium leading-[26px]'} style={{ color: textColor }}>{text}</span>
    </div>
  );
};

export default Button;

