import React from 'react';
import failed from '../assets/failed.png'
import Footer from '../components/common/Footer';
import Button from '../components/common/Button';
import { bottomSheet } from "react-simple-bottom-sheet";
import PaymentFailedTimeExp from './PaymentFailedTimeExp';
import PaymentFailedCancelled from './PaymentFailedCancelled'
import PaymentCancellationReason from './PaymentCancellationReason';

interface PaymentDetail {
  label: string;
  value: string;
}

const paymentDetails: PaymentDetail[] = [
  { label: 'Transaction ID', value: 'LP12345678923456' },
  { label: 'Payment Type', value: 'Card' },
];


const PaymentFailedCVV: React.FC = () => {
  const openPaymentAcquirer = () =>
    bottomSheet.create({
      content: <PaymentFailedTimeExp />,
    });

  const openCancelConfirmation = () =>
    bottomSheet.create({
      content: <PaymentCancellationReason />,
    });


  return (
    <div className='flex flex-col'>
      <div className='flex flex-col justify-center items-center gap-3 p-6'>
        <div>
          <img src={failed} alt='success-img' className='w-auto  h-auto object-contain' />
        </div>
        <div className='flex flex-col items-center gap-1 pb-5'>
          <div className='text-[14px] font-normal leading-[21px]'>Payment Failed</div>
          <div className='text-[24px] font-bold leading-[28px]'>Incorrect CVV Number</div>
          <div className='text-[14px] font-normal leading-[21px]'>Today, 10.30 AM</div>
        </div>
        <div className='font-normal text-center text-[14px] leading-[21px] font-gantari text-[#000000DE]'>
          If your money is debited from your account,<br></br>
          it will be refunded within 5-7 working days
        </div>
      </div>

      <div className='flex flex-col gap-[36px] bg-[#F5F5F5] px-6 pt-6'>
        <div className='flex flex-col'>
          {paymentDetails.map((detail, index) => (
            <div className='payment-details-container-list' key={index}>
              <div className='font-normal text-[14px] leading-[21px]'>{detail.label}</div>
              <div className='font-normal text-[14px] leading-[21px]'>{detail.value}</div>
            </div>
          ))}
        </div>
        <div className='flex flex-col gap-[16px]'>
          <Button
            text='Retry Payment'
            onClick={openPaymentAcquirer}
          />
          <button
            className='text-center px-2 py-[6px]'
            onClick={openCancelConfirmation}>
            Cancel Payment
          </button>
        </div>


      </div>
      <div className='bg-[#F5F5F5]'>
        <div className='p-6'>
          <Footer />
        </div>
      </div>

    </div>
  );
};

export default PaymentFailedCVV;
