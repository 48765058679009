// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import PaymentSummary from './pages/PaymentSummary';
import NotFound from './pages/NotFound';
import Paid from './pages/Paid';
import PaymentFailedCVV from './pages/PaymentFailedCVV';
import PaymentFailedTimeExp from './pages/PaymentFailedTimeExp';
import PaymentFailedCancelled from './pages/PaymentFailedCancelled';
import Layout from './components/specific/layout';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsandService from './pages/TermsandService';
import PaymentHomeSceen from './pages/PaymentHomeSceen';
import PaymentLinkExpired from './pages/PaymentLinkExpired';
import PaymentCancellationReason from './pages/PaymentCancellationReason';

const App: React.FC = () => {

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Navigate to="/lynkpay-payment" replace />} />
        <Route path="lynkpay-payment" element={<PaymentHomeSceen />} />
        <Route path="payment-summary" element={<PaymentSummary />} />
        <Route path="paid" element={<Paid />} />
        <Route path="payment-failed-CVV" element={<PaymentFailedCVV />} />
        <Route path="payment-time-exp" element={<PaymentFailedTimeExp />} />
        <Route path="cancelled-payment" element={<PaymentFailedCancelled />} />
        <Route path="cancellation-reason" element={<PaymentCancellationReason />} />
        <Route path="payment-link-expired" element={<PaymentLinkExpired />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-and-service" element={<TermsandService />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
};

export default App;
