import React from 'react';
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import timeout from "../assets/timeout.png";

interface HeaderProps {
  showExpiryText?: boolean;
  timeLeft?: string;
}

const PaymentLinkExpired = () => {
  return (
    <div className="flex flex-col w-full h-screen">
      <Header showExpiryText={false} timeLeft="0" />
      <div className="h-full flex flex-col items-center justify-center px-6 bg-[white] w-full gap-[20px] screen-size">
        <div className="flex flex-col gap-y-[12px] w-full items-center justify-center">
          <img src={timeout} alt="time-out" className="w-auto h-auto object-contain" />
          <p className="font-bold text-[24px] leading-7 text-center">Link Expired</p>
        </div>
        <p className="font-normal text-[14px] leading-[21px] text-center text-[#D32F2F]">
          The payment link you are trying to access has expired. Please contact the service provider for assistance.
        </p>
      </div>
      <div className='p-6 '>
        <Footer />
      </div>
    </div>
  );
};

export default PaymentLinkExpired;
